import * as Constants from "./constants";

/**
 * Checks if object is empty
 * @param obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

/**
 * Convert image with URL to image Data
 * @param url
 * @param callback
 */
export function toDataUrl(url, callback) {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    let reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

/**
 *
 * @param temp
 * @param mandatory
 * @returns {boolean}
 */
export function hasEmptyProperty(temp, mandatory) {
  for (var key in temp) {
    if (temp.hasOwnProperty(key))
      if (temp[key].length === 0) {
        if (mandatory && mandatory.length > 0) {
          if (mandatory.includes(key)) return key;
        } else {
          return false;
        }
      }
  }
  return false;
}

export function getIndustryId(industries, industry_name) {
  for (var i = 0; i < industries.length; i++) {
    if (industries[i].title.toLowerCase() === industry_name.toLowerCase()) {
      return industries[i].id.toString();
    }
  }
}

export const capitalizeFirstLowercaseRest = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
};

// Sort high to low
export const order = (elem1, elem2) => {
  return elem1 > elem2 ? -1 : elem1 < elem2 ? 1 : 0;
};

export const scroll = () => {
  document.getElementById("top").scrollIntoView({ behavior: "smooth" }, true);
};

export const transformIndustryData = (industryData) => {
  if (industryData) {
    if (industryData.name) {
      return { title: industryData.name, id: industryData.id };
    } else if (industryData.title) {
      return { title: industryData.title, id: industryData.id };
    }
  }
  return "";
};

export const camelCaseToNormal = (str) => {
  // Add a space before each uppercase letter and convert the whole string to lowercase
  let result = str.replace(/([A-Z])/g, " $1").toLowerCase();

  // Capitalize the first letter of the resulting string
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const normalToCamelCase = (str) => {
  if (str && str.length > 0) {
    // Split the string by spaces and convert all letters to lowercase except the first letter
    const words = str.split(" ").map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    // Join the words together without spaces
    return words.join("");
  }
};

// Check if user has completed all required assessments (true of false)
export const hasUserCompletedAllRequiredAssessments = (
  requiredAssessments,
  completedAssessments
) => {
  console.log("REQ")
  console.log(requiredAssessments)
  console.log("COMP")
  console.log(completedAssessments)
  const completedAssessmentIds = completedAssessments.map(
    (assessment) => assessment.instrument_id
  );
  return requiredAssessments.every((id) => completedAssessmentIds.includes(id));
};


export const calculateAverageCeiling = (traits) => {
  const sum = traits.reduce((acc, val) => acc + val, 0);
  const average = sum / traits.length;
  return Math.ceil(average);
};


export const getAONAssessmentLabelById = (inst_id) => {
  const assessment = Constants.AON_ASSESSMENTS.find(item => item.id === inst_id);
  return assessment ? assessment.label : null;
}
